<template>
  <div v-if="!isAuthenticated" class="text-justify">
    <md-card>
      <md-card-header class="card-divider">
        <div class="card-divider">
          <h3><b>Passwort setzen</b></h3>
        </div>
      </md-card-header>
      <md-card-content>
        <div class="card-section" style="height: 22rem;">
          <form v-if="!passwordSet" novalidate @submit.prevent="validatePassword">

            <md-field :class="getValidationClass('password')">
              <div class="grid-x" style="width: 100%">
                <div class="cell small-12 medium-12 large-4 text-left">
                  <label for="password">Passwort
                    <span v-if="passwordScore !== null && passwordScore < 3" style="color: orange; font-size: 12px;"><br>zu schwach</span>
                  </label>
                  <md-tooltip>Mit diesem Passwort können Sie sich nach erfolgreicher Registrierung anmelden.</md-tooltip>
                </div>
                <div class="cell small-12 medium-12 large-8 text-right">
                  <password id="password" v-model="form.password" :toggle="true" :secureLength="webAppSettings.pwdSecureLength"  @score="showPasswordScore" style="max-width: 100%;" :disabled="sending"/>
                </div>
              </div>
              <span class="md-error" v-if="!$v.form.password.required">Passwort ist notwendig</span>
              <span class="md-error" v-else-if="!$v.form.password.minlength">Passwort muss min. {{webAppSettings.pwdSecureLength}} Zeichen lang sein</span>
            </md-field>

            <div v-if="sending">
              <br><br><br><br><br>
              <vue-simple-spinner></vue-simple-spinner>
            </div>
            <div v-else>
              <router-link to="/Login">
                <div class="text-center"><a>Zurück zur Anmeldung</a></div>
                <md-tooltip md-direction="bottom" style="font-size: medium;">Zurück zur Anmeldung</md-tooltip>
              </router-link>
              <br>
              <button class="button expanded" type="submit"
                      :disabled="form.password.length === 0 || recaptchaResponse.length === 0 || token.length === 0">
                <i class="fi-torso"></i>&nbsp;<i class="fi-key"></i>&nbsp;&nbsp;&nbsp;Absenden
                <md-tooltip md-direction="bottom" style="font-size: medium;">Senden Sie uns ihr neues Passwort</md-tooltip>
              </button>
              <br>
              <vue-recaptcha
                  ref="recaptchaRef"
                  :sitekey="siteKey"
                  @verify="onVerify"
                  @expired="onExpired">
              </vue-recaptcha>
            </div>
          </form>
          <div v-else>
            <br>
            <div class="text-center">
              <h5>Passwort wurde neu gesetzt.</h5>
              <br><br>
              <p>Sie können sich nun wieder anmelden.</p>
              <br><br>
            </div>
            <router-link to="/Login">
              <div class="text-center"><a>Zurück zur Anmeldung</a></div>
              <md-tooltip md-direction="bottom" style="font-size: medium;">Zurück zur Anmeldung</md-tooltip>
            </router-link>
            <br>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
  import Spinner from 'vue-simple-spinner'
  import VueRecaptcha from 'vue-recaptcha';
  import TokenService from '../../services/TokenService'
  import HttpErrorHandler from '../../services/HttpErrorHandler'
  import User from "../../entities/User"
  import Password from 'vue-password-strength-meter'

  let base64 = require('base-64');

  import {validationMixin} from 'vuelidate'
  import {
    required,
    minLength,
  } from 'vuelidate/lib/validators'
  import InfoService from "../../services/InfoService";

  export default {
    name: 'PasswordReset',
    mixins: [validationMixin],

    components: {
      'vue-simple-spinner': Spinner,
      VueRecaptcha,
      User,
      Password,
    },

    mounted() {
      this.token = this.$route.params['token'];
      if (!this.isAuthenticated) {
        this.getLabelsLight();
      }
    },

    data: () => ({
      form: {
        password: '',
      },
      sending: false,
      siteKey: process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY,
      recaptchaResponse: '',
      passwordSet: false,
      token: '',
      passwordScore: null,
    }),

    methods: {

      showPasswordScore(score) {
        this.passwordScore = score;
      },

      getLabelsLight() {
        InfoService.getLabelsLight()
          .then(response => {
            this.$store.commit('labelsLightInfo', response.data);
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Laden der Labels');
          });
      },

      onVerify: function (response) {
        this.recaptchaResponse = response;
        localStorage.setItem('x-recaptcha', response);
      },

      onExpired: function () {
        this.resetCaptcha();
      },

      resetCaptcha() {
        this.recaptchaResponse = '';
        localStorage.removeItem('x-recaptcha');
      },

      getValidationClass(fieldName) {
        const field = this.$v.form[fieldName];
        if (field) {
          return {
            'md-invalid': field.$invalid && field.$dirty
          }
        }
      },

      validatePassword() {
        this.$v.$touch();
        if (!this.$v.$invalid && this.recaptchaResponse.length > 0) {
          let user = JSON.parse(JSON.stringify(User.user));
          user.initialpw = this.form.password;
          this.resetPassword(user, this.token);
        }
      },

      resetPassword(user, token) {
        this.sending = true;

        if (user.initialpw) {
          user.initialpw = base64.encode(user.initialpw);
        }

        TokenService.processToken({user: user}, token)
          .then(() => {
            this.sending = false;
            this.$store.commit('successMsg', 'Passwort wurde erfolgreich gesendet');
            this.passwordSet = true;
            this.resetCaptcha();
          })
          .catch(e => {
            this.sending = false;
            HttpErrorHandler.handleError(e, this, 'Fehler beim Senden des neuen Passworts');
            this.resetCaptcha();
            this.$refs.recaptchaRef.reset();
          });
      },
    },

    validations: {
      form: {
        password: {
          required,
          minLength: minLength(8)
        },
      }
    },
    computed: {
      webAppSettings() {
        return this.$store.getters.webAppSettings
      },

      isAuthenticated() {
        return this.$store.getters.authenticated
      },
      role() {
        return this.$store.getters.role
      },
      isSage() {
        return this.$store.getters.sage
      },
      isAdmin() {
        return this.$store.getters.admin
      },
    }
  }
</script>

<style lang="scss" scoped>
  input, input:focus {
    border-width: 0;
    box-shadow: none;
  }

  p {
    color: darkslategray;
    font-weight: normal;
  }

  h5 {
    font-weight: 300;
  }

  h5 b {
    font-weight: 400;
    color: cadetblue;
  }
</style>