<template>
  <div class="password-reset-view">
    <passwordReset/>
  </div>
</template>

<script>
  // @ is an alias to /src
  import passwordReset from '@/components/authorization/PasswordReset.vue'

  export default {
    name: 'PasswordReset',
    components: {
      passwordReset
    }
  }
</script>

<style scoped>
  .password-reset-view {
    width: 21rem;
    height: 20rem;
    display: inline-block;
    position: fixed;
    left: 50%;
    top: 42%;
    margin-left: -10rem;
    margin-top: -10rem;
  }
</style>